import React from 'react';
import './main.css'
function Homepage() {
  return (
    <div className="container">
    <div className="sidebar"></div>
    <div className="content">
        <div className="box">
            <div className="header">
                <h1>The Teleological Foundation of Los Angeles</h1>
                <h4>"Tip the world over on its side and everything loose will land in Los Angeles." - Frank Lloyd Wright</h4>
                <hr/>

              </div>
              <div className="main">                           
                <video className='special 'autoPlay  muted loop>
                <source src="/video.mp4" type="video/mp4" />
    <source src="/video.ogg" type="video/ogg" />
                      Your browser does not support HTML5 video.
                </video>
                <p>At the TFLA we believe an interface is nothing less than tyranny. And for too long there have been far too few tyrants. We should have a multitude of interfaces that respond to the cycles of the moon, weather patterns, battery percentages, heart rates, gut biota, handedness, and more. Instead, we are imposed by the same cast of cruel and monotonous interfaces. With data inaccessible, scattered around the web and physical world, it’s pointless to build an untethered interface. Most data wants to be free: concert listings, movie listings, gallery openings, etc. Event hosts, at their most optimistic, may be described as naive when they look for accommodating platforms and marketing contracts, all pledging maximum outreach to their 'Ideal Audience.' We think it’s time to grow up! These dynamics only strive to perpetuate the status quo. We are tired of the noise. We are not afraid to say, we demand more wonder and coincidences on the web and in our cities!  We need to start these experiments now! We aren’t waiting any longer for our interfaces to improve. So here we begin by centralizing and freeing data in our city, Los Angeles. Please take our data and use it. Please offer us data to host. Please host your data. More to come soon.</p>

                <p>Positioned strategically within the vibrant cultural hub of the city, the Teleological Foundation of Los Angeles (TFLA) operates as a subtle catalyst, orchestrating wonder and action in the interwoven tapestry of Los Angeles.</p>
                <p>TFLA serves as an agent of transformative encounters, navigating the intricate interplay between the physical and digital realms. Through purposeful interventions and meticulous analysis, we empower individuals to forge non-normative connections within the dynamic. Drawing inspiration from the diverse narratives and multilayered imagery that permeate the city, TFLA challenges conventional perceptions and invites residents to embark on a journey of profound perception.</p>
                <p>By toppling the world on its side, TFLA disrupts the traditional boundaries that confine our understanding, transcending the mundane and revealing extraordinary possibilities. With an unwavering commitment to reshaping the urban landscape, we work to cultivate a cohesive and engaged community, where the agency of each resident contributes to the collective transformation of Los Angeles.</p>
 
                <ul>
                </ul>
              </div>
              <hr />
              <div className="footer">
              <p>For any inquiries please reach out to <a href="mailto:TFLA@good1s.org">tfla@good1s.org</a></p>
                <hr/>
                <p>Thank you for visiting the Teleological Foundation of Los Angeles.</p>
         
                <p>Copyright © 2023</p>
              </div>
        </div>
    </div>
    <div className="sidebar"></div>

  </div>     

  );
}

export default Homepage;



