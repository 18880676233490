import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import './form.css'
function Register() {
  const [status, setStatus] = useState('');
  const [validToken, setValidToken] = useState([]);
  const SITE_KEY = process.env.REACT_APP_reCAPTCHA_SITE_KEY;
  const captchaRef = useRef(null);
  const navigate = useNavigate();

  const verifyToken = async (token) => {
    try {
      const response = await fetch('/api/verify-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          reCAPTCHA_TOKEN: token,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return [data];
    } catch (error) {
      console.error('Error verifying token:', error);
      throw error; // Re-throw the error to propagate it to the caller if needed
    }
  };

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let token = captchaRef.current.getValue();
    captchaRef.current.reset();

    // Perform client-side validation here (e.g., check for valid email format, password strength)

    if (token) {
      try {
        let valid = await verifyToken(token);
        setValidToken(valid);

        if (validToken[0].success === true) {
          console.log('Verified');
          // Send registration data to the server (you'll need to implement this)
          const { username, email, password } = formData;
          const response = await fetch('/api/register', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, email, password }),
          });

          if (!response.ok) {
            throw new Error('Registration failed');
          }

          console.log('Registration successful');
          navigate('/login');
          // Handle the response (e.g., show a success message, redirect to login)
        }
      } catch (error) {
        console.error('Registration error:', error);
        setStatus('Registration failed. Please try again later.');
      }
    } else {
      console.log('Not verified');
      setStatus('Sorry!! Verify you are not a bot');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <div className="center">
          <ReCAPTCHA
            className="recaptcha"
            sitekey={SITE_KEY}
            ref={captchaRef}
          />
        </div>
        <button type="submit">Register</button>
        {status}
      </form>
    </div>
  );
}

export default Register;
