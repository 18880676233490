import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
function EditListingForm({ onChange, listing,theaters }) {
  // Initialize the movies state with the movies from the listing
  const [movies, setMovies] = useState(listing.movies || []);
  // Define state variables to store form field values
  const [theater, setTheater] = useState(listing.theater || '');
  const initialDate = listing.date && !isNaN(new Date(listing.date)) ? new Date(listing.date) : '';
  const [date, setDate] = useState(initialDate);
  const [dateString, setDateString] = useState(listing.dateString || '');
  const [QA, setQA] = useState(listing.QA || false); // Assuming QA is a boolean
  const [series, setSeries] = useState({
    title: listing.series?.title || '',
    url: listing.series?.url || '',
  });
  const [URL, setURL] = useState(listing.URL || '');
  const [note, setNote] = useState(listing.note || '');
  const [description, setDescription] = useState(listing.description || '');
  const [title, setTitle] = useState(listing.title || '');
  const edited = true;
  const [updateMessage, setUpdateMessage] = useState(''); // State for update message
  const validateForm = () => {
    if (!theater) {
      alert('Please select a theater.');
      return false;
    }
  
    if (!date) {
      alert('Please select a date and time.');
      return false;
    }
    if (!URL) {
        alert('Please enter a URL.');
        return false;
      }
    if (!title) {
      alert('Please enter an event title.');
      return false;
    }
  
    // Add additional validation checks for other fields if needed
  
    return true; // All validation checks passed
  };
  // Handle form submission
  const handleDateChange = (date) => {
        setDate(date);
        setDateString(date.getFullYear()+date.getMonth()+date.getDate()+date.getHours()+date.getMinutes());
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
        return; // Stop the submission if validation fails
      }
    // Create a new listing object based on the form field values
    const newListing = {
      theater,
      date,
      dateString,
      QA,
      series,
      URL,
      note,
      title,
      edited,
      description,
      movies, // Include the array of movies in the listing
    };

    try {
      const response = await fetch('/api/editListing/' + listing._id, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include cookies
        body: JSON.stringify(newListing),
      });
      if (response.ok) {
        // Success: Listing updated successfully
        // You can display a success message here if needed
        setUpdateMessage('Listing updated successfully.');
        onChange()
        // You can close the form here by calling a callback function if needed
    } else {
        // Error: An error occurred while updating the listing
        const errorData = await response.json();
        console.error(errorData.message); // Log the error message for debugging
        // Update the UI to display the error message to the user
        // For example, you can set it in a state variable to render in your component
    }

    } catch (error) {
      // Handle fetch error
      setUpdateMessage('An error occurred while updating the listing.');
    }
  };

  // Function to add a new movie field
  const addMovieField = () => {
    setMovies([...movies, { title: '', runtime: '', director: '', year: '', description: '', format: '' }]);
  };

  // Function to remove a movie by index
  const removeMovie = (indexToRemove) => {
    if (indexToRemove >= 0 && indexToRemove < movies.length) {
      const updatedMovies = [...movies];
      updatedMovies.splice(indexToRemove, 1); // Remove the movie at the specified index
      setMovies(updatedMovies); // Update the state with the modified array
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {/* Fields for listing properties */}
        <label>
  Theater:
  <select
    value={theater}
    onChange={(e) => setTheater(e.target.value)}
  >
    <option value="">Select a Theater</option> {/* Default empty option */}
    {theaters.map((theaterItem) => (
      <option key={theaterItem.key} value={theaterItem.key}>
        {theaterItem.name}
      </option>
    ))}
  </select>
</label>
        <br />
        <label>Date and Time:</label>
        <DatePicker
          selected={date}
          onChange={(date) => handleDateChange(date)}
          timeInputLabel="Time:"
          dateFormat="MM/dd/yyyy h:mm aa"
          showTimeInput
        />
        <br />
        <label>
         Event Title:
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
        </label>
        <br />
        <label>
          URL:
          <input type="text" value={URL} onChange={(e) => setURL(e.target.value)} />
        </label>
        <br />
        <label>
          QA:
          <input type="checkbox" checked={QA} onChange={() => setQA(!QA)} />
        </label>
        <br />
        <label>
          Series Title:
          <input
            type="text"
            value={series.title}
            onChange={(e) => setSeries({ ...series, title: e.target.value })}
          />
        </label>
        <br />
        <label>
          Series URL:
          <input
            type="text"
            value={series.url}
            onChange={(e) => setSeries({ ...series, url: e.target.value })}
          />
        </label>
        <br />
        <label>
          Note:
          <textarea value={note} onChange={(e) => setNote(e.target.value)} />
        </label>
        <br />
        <label>
          Description:
          <textarea value={description} onChange={(e) => setDescription(e.target.value)} />
        </label>
        <br />

        {/* Fields for movies */}
        <h3>Movies:</h3>
        {movies.map((movie, index) => (
          <div key={index}>
            <label>
              Movie Title:
              <input
                type="text"
                value={movie.title}
                onChange={(e) => {
                  const updatedMovies = [...movies];
                  updatedMovies[index].title = e.target.value;
                  setMovies(updatedMovies);
                }}
              />
            </label>
            <br />
            <label>
              Movie Director(s):
              <input
                type="text"
                value={movie.director}
                onChange={(e) => {
                  const updatedMovies = [...movies];
                  updatedMovies[index].director = e.target.value;
                  setMovies(updatedMovies);
                }}
              />
            </label>
            <br />
            <label>
              Movie Runtime in Minutes:
              <input
                type="number"
                min="1"
                max="720"
                value={movie.runtime}
                onChange={(e) => {
                  const updatedMovies = [...movies];
                  updatedMovies[index].runtime = e.target.value;
                  setMovies(updatedMovies);
                }}
              />
            </label>
            <br />
            <label>
              Movie Release Year:
              <input
                type="number"
                min="1878"
                max="2100"
                value={movie.year}
                onChange={(e) => {
                  const updatedMovies = [...movies];
                  updatedMovies[index].year = e.target.value;
                  setMovies(updatedMovies);
                }}
              />
            </label>
            <br />
            <label>
              Movie Format:
              <select
                value={movie.format}
                onChange={(e) => {
                  const updatedMovies = [...movies];
                  updatedMovies[index].format = e.target.value;
                  setMovies(updatedMovies);
                }}
              >
                <option  disabled value=""> Select a format </option>
                <option value="Digital">Digital</option>
                <option value="35mm">35mm</option>
                <option value="DCP">DCP</option>
                <option value="70MM">70MM</option>
                <option value="16MM">16MM</option>
                <option value="VCR">VCR</option>
                {/* Add other predefined options */}
                <option value="custom">Other</option>
              </select>
            </label>
            {/* Render custom input if the user selects "Other" */}
            {movie.format === 'custom' && (
              <label>
                Format:
                <input
                  type="text"
                  value={movie.customFormat || ''}
                  onChange={(e) => {
                    const updatedMovies = [...movies];
                    updatedMovies[index].customFormat = e.target.value;
                    setMovies(updatedMovies);
                  }}
                />
              </label>
            )}
            {index > 0 && (
              <button type="button" onClick={() => removeMovie(index)}>
                Remove Movie
              </button>
            )}
            <br />
            <br />
            {/* Add fields for other movie properties */}
          </div>
        ))}
        <button type="button" onClick={addMovieField}>
          Add Another Movie
        </button>
        <p />

        {/* Display update message */}
        {updateMessage && <p>{updateMessage}</p>}

        <button type="submit">Update Listing</button>
      </form>
      <br />
    </div>
  );
}

export default EditListingForm;
