import React from 'react';
import randomcolor from 'randomcolor';

function TheaterButtons({ allTheaters, showAll, theaters, handleHideTheater, handleShowTheater,handleShowAll}) {
  // Generate a single color for "show" buttons
  const showButtonColor = randomcolor({ luminosity: 'light' });

  // Generate a single color for "hide" buttons
  const hideButtonColor = randomcolor({ luminosity: 'light' });

  return (
    <div>
      {allTheaters.map((t, index) => (
        <button
          key={t.key}
          onClick={() => (theaters.includes(t.key) ? handleHideTheater(t.key) : handleShowTheater(t.key))}
          style={{
            backgroundColor: theaters.includes(t.key) ? hideButtonColor : showButtonColor,
            color: 'black',
            fontFamily: 'serif',
            fontWeight: 'bold',
            borderRadius: '5px',
            fontSize: "16px",
            transition: 'background-color 0.3s',
          }}
          className="alpers-button"
        >
          {theaters.includes(t.key) ? `Hide ${t.name}` : `Show ${t.name}`}
        </button>
      ))}
      <br />
      <div>
<br/>
     <button
          onClick={() => (!(theaters.length===0) ? handleHideTheater('all') : handleShowTheater('all'))}
          style={{
            backgroundColor: !(theaters.length===0) ? hideButtonColor : showButtonColor,
            color: 'black',
            fontFamily: 'serif',
            fontWeight: 'bold',
            borderRadius: '5px',
            fontSize: "20px",
            transition: 'background-color 0.3s',
          }}
          className="alpers-button"
        >
          {!(theaters.length===0) ? `Hide All` : `Show All`}
        </button> 

  <button
    onClick={() => handleShowAll()}
    style={{
        backgroundColor: !(theaters.length===0) ? hideButtonColor : showButtonColor,
        color: 'black',
        fontFamily: 'serif',
        fontWeight: 'bold',
        borderRadius: '5px',
        fontSize: "20px",
        transition: 'background-color 0.3s',
      }}
      className="alpers-button"
    
  >
    {
        (showAll) ? `Close All Listing Bodies` : `Open All Listing Bodies`
    }
  </button>
  </div>

    </div>
  );
}

export default TheaterButtons;
