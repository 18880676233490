import React from 'react';
import './main.css'
function Movies() {
  return (
    <div className="container">
    <div className="sidebar"></div>
    <div className="content">
        <div className="box">
            <div className="header">
                <h1>The Teleological Foundation of Los Angeles</h1>
                <hr/>

              </div>
              <div className="main">
                <p>Most people use our <a href='/interface'>interface</a> <a href='https://good1s.org'>good1s.org</a>.</p>
                <p>A get request to <a href='./movies/theaters'>/movies/theaters</a> returns a JSON dump of our theaters.</p>
                <p>A get request to <a href='./movies/academy'>/movies/"theater-key"</a> returns a JSON dump of the current listings of the theater.</p>
                <p>Navigate to <a href='./movies/academy.ics'>/movies/"theater-key.ics"</a> to download and subscribe to ICalender (ICS) files for each theater. </p>
                <p>Most new listing data comes in at 2:00 am nightly. See logs <a href="./movies/logs">here</a>.</p>
                <p>Please use our data and make your own interface! Email us with any questions. A better API and ICS support coming soon.</p>
                <p>If you are a theater or programmer in Los Angeles and would like to get in touch email us!</p>
              </div>
              
              <hr />
              <div className="footer">
              <p>For any inquiries please reach out to <a href="mailto:TFLA@good1s.org">tfla@good1s.org</a></p>
                <hr/>
                <p>Thank you for visiting the Teleological Foundation of Los Angeles.</p>
         
                <p>Copyright © 2023</p>
              </div>
        </div>
    </div>
    <div className="sidebar"></div>

  </div>     

  );
}

export default Movies;



