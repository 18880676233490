import React from 'react';
import RegisterForm from './RegisterForm';
import './main.css'

function RegisterPage() {
  return (
    <div className="container">
<div className="sidebar"></div>
<div className="content">
    <div className="box">
        <div className="header">
            <h1>The Teleological Foundation of Los Angeles</h1>
            <h4>"Tip the world over on its side and everything loose will land in Los Angeles." - Frank Lloyd Wright</h4>
            <hr/>
            <h3>Register</h3>                       

          </div>
          <div className="main">    
          <RegisterForm />
          </div>

    </div>
</div>
<div className="sidebar"></div>
    </div>
  );
}

export default RegisterPage;