import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function LoginForm({ setRole, setIsAuthenticated }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send a request to your server to authenticate the user
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        const { role } = data;

        // Set the user's role and authentication status
        setRole(role);
        setIsAuthenticated(true); // Set authentication status to true
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('role', role);


        // Redirect to another page or perform further actions
        setStatus('Login success!');
        navigate('/dashboard');
      } else {
        // Handle login error
        console.error('Login failed');
        setStatus('Login failed. Try again.');
        setPassword('');
        setEmail('');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <button type="submit">Login</button>
        </div>
        <span>{status}</span>
      </form>
    </div>
  );
}

export default LoginForm;
