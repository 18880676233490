import React, { useState } from 'react';

function EditTheaterForm({ theater, onUpdate }) {
  // Define state variables to store form field values
  const [name, setName] = useState(theater.name);
  const [url, setUrl] = useState(theater.url);
  const [key, setKey] = useState(theater.key);
  const [location, setLocation] = useState(theater.location);
  const [description, setDescription] = useState(theater.description);
  const [message, setMessage] = useState('');

  const validateForm = () => {
    if (!name) {
      alert('Please enter a name for the theater.');
      return false;
    }

    if (!url) {
      alert('Please enter a URL for the theater.');
      return false;
    }

    if (!key) {
      alert('Please enter a unique key for the theater.');
      return false;
    }
    
    // Add additional validation checks for other fields if needed

    return true; // All validation checks passed
  };

  const handleSubmit = async (e) => {
    const confirmation = window.confirm('Are you sure you want to edit this Theater?');
    if(confirmation) {
        e.preventDefault();

    // Validate the form fields
    if (!validateForm()) {
      return; // Stop the submission if validation fails
    }

    // Create an updated theater object based on the form field values
    const updatedTheater = {
      name,
      url,
      key,
      location,
      description,
    };

    try {
      const response = await fetch(`/api/editTheater/${theater._id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include cookies
        body: JSON.stringify(updatedTheater),
      });

      if (response.ok) {
        // Success: Theater edited successfully
        // You can display a success message here if needed
        console.log(response);
        // Notify the parent component of the update
        setMessage('Theater successfully edited!')
        onUpdate();
      } else {
        // Error: An error occurred while editing the theater
        const errorData = await response.json();
        console.error(errorData.message); // Log the error message for debugging
        // Update the UI to display the error message to the user
        // For example, you can set it in a state variable to render in your component
        setMessage('Error editing theater. Try again');
    } }catch (error) {
      // Handle fetch error
      console.error('An error occurred while editing the theater.', error);
    }
    }
  };

  return (
    <div>
      <h2>Edit Theater</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Theater Name:
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
        </label>
        <br />
        <label>
          Theater URL:
          <input type="text" value={url} onChange={(e) => setUrl(e.target.value)} />
        </label>
        <br />
        <label>
          Theater Key:
          <input type="text" value={key} onChange={(e) => setKey(e.target.value)} />
        </label>
        <br />
        <label>
          Location:
          <input type="text" value={location} onChange={(e) => setLocation(e.target.value)} />
        </label>
        <br />
        <label>
          Description:
          <textarea value={description} onChange={(e) => setDescription(e.target.value)} />
        </label>
        <br />
        <button type="submit">Update Theater</button>
      </form>
      <span>{message}</span>
      <br />
    </div>
  );
}

export default EditTheaterForm;
