import React, { useEffect, useState } from 'react';
//edit user theaters function could be impoted

function UserManage({role}) {
  const [users, setUsers] = useState([]);
  const [allTheaters, setAllTheaters] = useState([]);

  const [theaterEditId, setTheaterEditId] = useState();
  const [selectedTheaters, setSelectedTheaters] = useState([]); // State to manage selected theaters
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Check if the authentication cookie exists using js-cookie

      fetchUsers();
      fetchTheaters();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch('/api/getAllUsers', {
        credentials: 'include', // Include cookies
      });

      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      } else {
        console.log('Error fetching users');
      }
    } catch (error) {
      console.error('Error fetching users', error);
    }
  };
  const fetchTheaters = async () => {
    try {
      const response = await fetch('/api/getTheaters', {
        credentials: 'include', // Include cookies
      });

      if (response.ok) {
        const data = await response.json();
        setAllTheaters(data);
      } else {
        console.log('Error fetching theaters');
      }
    } catch (error) {
      console.error('Error fetching theaters', error);
    }
  };
  const handleTheaterEditId = (userId) => {
    setTheaterEditId(userId);
    const user = users.find((user) => user._id === userId);
    setSelectedTheaters(user.theaterIds || []); // Initialize selected theaters with the user's current theaters
  };

  const handleMakeUserEditor = async (userId) => {
    const confirmation = window.confirm('Are you sure you want to make this user an editor?');
    if(confirmation) {
    try {
        await fetch(`/api/makeEditor/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include cookies
        });

        fetchUsers();
    } catch (error) {
        console.error('Error making user editor', error);
    }
    }
  };

  const handleMakeEditorUser = async (userId) => {
    const confirmation = window.confirm('Are you sure you want to make this editor a user?');
    if(confirmation) {
    try {
        await fetch(`/api/makeUser/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include cookies
        });

        fetchUsers();
    } catch (error) {

        console.error('Error making editor user', error);
    }
    }
  };
  const handleCloseEditor = () => {
    setMessage('');
    setTheaterEditId('');
  }
  const handleEditorTheater = async (e) => {
    e.preventDefault()
    try {
      
      // Construct the request body with selected theaters
      const requestBody = {
        theaters: selectedTheaters,
      };
      // Send a POST request to update the user's theaters
      const response = await fetch(`/api/setUserTheaters/${theaterEditId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include cookies
        body: JSON.stringify(requestBody),
      });
  
      if (response.ok) {
        // Successfully updated theaters
        console.log(response);
        fetchUsers(); // Refresh user data
        setMessage('User theaters updated');
      } else {

        throw new Error('Error updating user theaters');
      }
    } catch (error) {
      console.error('Error updating user theaters', error);
      setError('An error occurred while updating user theaters.');
    }
  };
  
  const getTheaterNames = (theaterIds) => {
    let temp = allTheaters.filter(theater=>theaterIds.includes(theater.key));
    return [temp.map(t=>t.name)]
  }
  return (
    <div>
{error && <p>Error: {error}</p>}
{role === "admin" ? (<>{users.map((user) => (
  <div key={user._id}>
     {user.role} - {user.username} - {user.email} {user.name} <br />
         
          {user.role === 'user' && getTheaterNames(user.theaterIds).length > 0 ? (
            <span>Theaters: {getTheaterNames(user.theaterIds).join(', ')} <br /></span>
          ) : null}
          {user.role === 'user' && theaterEditId !== user._id ? (
            <button onClick={() => handleTheaterEditId(user._id)}>Edit Theaters</button>
          ) : null}
          {user.role !== 'admin'? (user.role === 'user' ? (
            <button onClick={() => handleMakeUserEditor(user._id)}>Make User Editor</button>
          ) : (
            <button onClick={() => handleMakeEditorUser(user._id)}>Make Editor User</button>
          )) : null}
          
          {theaterEditId === user._id ? (
            <>
            <form onSubmit={handleEditorTheater}>
              {allTheaters.map((theater) => (
                <div key={theater._id}>
                  <label>
                    <input
                      type="checkbox"
                      value={theater.key}
                      checked={selectedTheaters.includes(theater.key)} // Check if the theater is selected
                      onChange={(e) => {
                        const theaterKey = e.target.value;
                        if (e.target.checked) {
                          setSelectedTheaters([...selectedTheaters, theaterKey]); // Add theater to selected theaters
                        } else {
                          setSelectedTheaters(selectedTheaters.filter((key) => key !== theaterKey)); // Remove theater from selected theaters
                        }
                      }}
                    />
                    {theater.name}
                  </label>
                </div>
              ))}
              <button type="submit">Save Theaters</button>

            </form>
                          <button onClick={handleCloseEditor}>Close Theater Add</button>
                          <br />
                          {message}
    </>
          ) : null}
          <br/>
        </div>      ))} </>)
                 : null   }
    </div>
  );
}

export default UserManage;
