import React, { useEffect, useState } from 'react';
import AddListingForm from './AddListing';
import EditListing from './EditListing';
import TheaterButtons from './TheaterButtons';
import AddTheaterForm from './AddTheater';
import EditTheaterForm from './EditTheater';
import DeleteTheater from './DeleteTheater';

function Dashboard(role) {
  const [listings, setListings] = useState([]);
  const [showListings, setShowListings] = useState(true);
  const [theaters, setTheaters] = useState([]);
  const [allTheaters, setAllTheaters] = useState([]);
  const [addListing, setAddListing] = useState(false);
  const [editId, setEditId] = useState();
  const [showId, setShowId] = useState();
  const [showAll, setShowAll] = useState(false);
  const [showAllTheaters, setShowAllTheaters] = useState(false);
  const [addTheater, setAddTheater] = useState(false);
  const [editTheater, setEditTheater] = useState();
  const [reverse, setReverse] = useState(false);

  useEffect(() => {
    // Check if the authentication cookie exists using js-cookie

      fetchListings();
      fetchTheaters();
  }, []);
  const fetchListings = async () => {
    try {
      const response = await fetch('/api/getAllListings', {
        headers: {
          'Content-Type': 'application/json', // Use a function to get the cookie value
                },
                credentials: 'include', // Include cookies
      });
      if (response.ok) {
        const data = await response.json();
        if(reverse) {
          const sorted = data.sort((b, a) => new Date(a.date) - new Date(b.date));
          setListings(sorted);
        } else {
          const sorted = data.sort((a, b) => new Date(a.date) - new Date(b.date));
          setListings(sorted);
        }
        
      } else {
        console.log('Error fetching listings');
      }
    } catch (error) {
      console.error('Error fetching listings', error);
    }
  };

  const fetchTheaters = async () => {
    try {
      const response = await fetch('/api/getTheaters', {
        headers: {
          'Content-Type': 'application/json', // Use a function to get the cookie value
                },
                credentials: 'include', // Include cookies
      });

      if (response.ok) {
        const data = await response.json();
        const theaterKeys = data.map((d) => d.key);
        setTheaters(theaterKeys);
        setAllTheaters(data.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }));
      } else {
        console.log('Error fetching theaters');
      }
    } catch (error) {
      console.error('Error fetching theaters', error);
    }
  };

  
  const handleHideListing = async (listingId) => {
    const confirmation = window.confirm('Are you sure you want to hide this listing?');
if(confirmation) {
  try {
    await fetch(`/api/hideListing/${listingId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Use a function to get the cookie value
              },
        credentials: 'include', // Include cookies
    });

    fetchListings();
  } catch (error) {
    console.error('Error hiding listing', error);
  }
}
  };

  const handleUnhideListing = async (listingId) => {
    try {
      await fetch(`/api/unhideListing/${listingId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Use a function to get the cookie value
                },
                credentials: 'include', // Include cookies
      });

      fetchListings();
    } catch (error) {
      console.error('Error unhiding listing', error);
    }
  };

  const handleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  const handleEditListing = (listingId) => {
    setEditId(listingId);
  };

  const handleAddListing = (listingId) => {
    if(addListing) {
      setAddListing(false);
    }
    else {
      setAddListing(true);
    }
  };
  const handleShowListings = () => {
    if(showListings) {
      setShowListings(false);
    }
    else {
      setShowListings(true);
    }
  };
  const handleShowListing = (listingId) => {
    setShowId((prevShowId) => (prevShowId === listingId ? null : listingId));
  };
  const handleShowAllTheaters = () => {
    if(showAllTheaters) {
      setShowAllTheaters(false)
      setAddTheater(false);
    }
    else {
      setShowAllTheaters(true)
    }
  }
  const handleAddTheater = () => {
    if(addTheater) {
      setAddTheater(false)
    }
    else {
      setAddTheater(true)
    }
  }
 const handleEditTheater = (theaterId) => {
    if(theaterId===editTheater) {
      setEditTheater(0);
    }
    else {
      setEditTheater(theaterId);
    }
 }
const handleListings = () => {
  fetchListings();
}
const handleTheaters = () => {
  fetchTheaters();
}
  const confirmDeleteListing = async (listingId) => {
    const confirmation = window.confirm('Are you sure you want to delete this listing?');
    if (confirmation) {
      try {
        await fetch(`/api/deleteListing/${listingId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json', // Use a function to get the cookie value
                  },
                  credentials: 'include', // Include cookies
        });

        fetchListings();
      } catch (error) {
        console.error('Error deleting listing', error);
      }
    }
  };
const handleDeleteListing = (listingId) => {
  confirmDeleteListing(listingId);
  fetchListings();

}
  const handleShowTheater = (theater) => {
    if (theater === 'all') {
      setTheaters(allTheaters.map((i) => i.key));
    } else {
      const newTheaters = [...theaters, theater];
      setTheaters(newTheaters);
    }
  };

  const handleHideTheater = (theater) => {    
    if (theater === 'all') {
      setTheaters([]);
    } else {
      const newTheaters = theaters.filter((i) => i !== theater);
      setTheaters(newTheaters);
    }
  };
  const handleReverse = () => {
    if(reverse) {
      const sorted = listings.sort((a, b) => new Date(a.date) - new Date(b.date));
      setListings(sorted);
      setReverse(false);
    } else {
      const sorted = listings.sort((b, a) => new Date(a.date) - new Date(b.date));
      setListings(sorted);
      setReverse(true);
    }
  };

  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  function extractAnchorText(title) {
    const anchorPattern = /<a[^>]*>([^<]*)<\/a>/i;
    const match = title.match(anchorPattern);
    return match ? match[1] : title;
  }

  return (
    <div>
      <h2>Dashboard</h2>
     
  <button
    onClick={() => handleShowListings()}
    style={{
        backgroundColor: 'rgb(247, 140, 255)',
        color: 'black',
        fontFamily: 'serif',
        fontWeight: 'bold',
        borderRadius: '5px',
        fontSize: "20px",
        transition: 'background-color 0.3s',
      }}
    
  >
    {
        (showListings) ? `Close Listings` : `Open Listings`
    }
  </button>
  <button
    onClick={() => handleShowAllTheaters()}
    style={{
        backgroundColor: 'rgb(207, 196, 255)',
        color: 'black',
        fontFamily: 'serif',
        fontWeight: 'bold',
        borderRadius: '5px',
        fontSize: "20px",
        transition: 'background-color 0.3s',
      }}
    
  >
    {
        (showAllTheaters) ? `Hide Theaters` : `Open Theaters`
    }
  </button>
{
  showAllTheaters && (role.role === "admin" || role.role === 'editor') && <p> <button
  onClick={() => handleAddTheater()}
  style={{
      backgroundColor: 'rgb(175, 255, 233)',
      color: 'black',
      fontFamily: 'serif',
      fontWeight: 'bold',
      borderRadius: '5px',
      fontSize: "20px",
      transition: 'background-color 0.3s',
    }}
  
>
  {
      (addTheater) ? `Close Add Theater` : `Add New Theater`
  }

</button></p>
}

{         (addTheater) ? <AddTheaterForm onUpdate={handleTheaters}  /> : null
}

{showListings && <p><button
    onClick={() => handleAddListing()}
    style={{
        backgroundColor: 'rgb(161, 249, 132)',
        color: 'black',
        fontFamily: 'serif',
        fontWeight: 'bold',
        borderRadius: '5px',
        fontSize: "20px",
        transition: 'background-color 0.3s',
      }}
    
  >
    {
        (addListing) ? `Close Add Listings` : `Add Listings`
    }
  </button></p> }
  {         (addListing && showListings) ? <AddListingForm onChange={handleListings} theaters={allTheaters} /> : null
}

{(showAllTheaters)? allTheaters.map((theater)=>(
   <p key={theater._id}>
<a  rel='noreferrer' target='_blank' href={theater.url}>{theater.name}</a> <br/>
  key: {theater.key},
   location: {theater.location}
   <br />
   <button
    onClick={() => handleEditTheater(theater._id)}
  >
    {
        (editTheater === theater._id) ? `Close Editor` : `Edit Theater`
    }
  </button>
  {
    editTheater === theater._id && <EditTheaterForm theater={theater} onUpdate={handleTheaters} />
  }
{<DeleteTheater theater={theater} onUpdate={handleTheaters} />}
   </p>
   
  
  )) : null}
     { (showListings) ? (<TheaterButtons
        allTheaters={allTheaters}
        showAll={showAll}
        handleShowAll={handleShowAll}
        theaters={theaters}
        handleHideTheater={handleHideTheater}
        handleShowTheater={handleShowTheater}
      />) : null }
    { (showListings) ?   <p> <button  onClick={() => handleReverse()} >
    {
        (reverse) ? `Chronological Order` : `Reverse Chronological`
    }
     </button> </p>: null }
        { (showListings) ? (<div>
        {listings.filter((i) => theaters.includes(i.theater)).map((listing) => (
            <div key={listing._id}>
          {(listing._id === editId) ? (
                <button onClick={() => handleEditListing(0)}>
                  Close Editor (press update to save changes)
                </button>
              ) : null}              <p>{(new Date(listing.date)).toLocaleString(undefined, options)} <br/><a href={listing.URL} rel='noreferrer' target='_blank'>{listing.title ? extractAnchorText(listing.title) : 'NO TITLE'}</a>{listing.movies.length ===1 && listing.movies[0].year ? ` - ${listing.movies[0].year}` : null} <br />{allTheaters.find((t) => t.key === listing.theater).name}               
</p> 

              
              {!(listing._id === editId) && (showAll || (listing._id === showId)) && (
                <div>
                  {listing.series ? (
                    <div>
                      Series: <a href={listing.series.url}>{listing.series.title}</a>
                    </div>
                  ) : 'No series  '} 
                   {listing.note ? (
                    <div>
                      Note: {listing.note.includes('eventHero__intro') ? 'NEEDS FORMATING': listing.note}
                    </div>
                  ) : 'No note  '}
                  {listing.description ? (
                    <div>
                      Description: {listing.description.includes('eventHero__intro') ? 'NEEDS FORMATING': listing.description}
                    </div>
                  ) : 'No description  '}
                  {listing.QA ? (
                    <div>
                      Q&A
                    </div>
                  ) : 'No Q&A  '}
                  {listing.movies.map((m, index) => (
                    <div key={m._id} id={m.title}>
                      {m.title} {m.year}<br />
                       {m.director ? ('dir. '+ m.director) : ('no director')} {m.format ? (m.format) : ('no format')} {m.runtime ? (m.runtime +' mins') : ('no runtime')}<br />
                      {m.description} 
                    </div>
                  ))}
                </div>
              )}
                            {!(listing._id === editId) && !showAll? (
                <p><button onClick={() => handleShowListing(listing._id)}>
                  {listing._id === showId ? 'Close Listing' : 'Open Listing'}
                </button> </p>
              ) : null}
    
             <p> {(editId === listing._id) ? <EditListing listing={listing} onChange={handleListings} theaters={allTheaters} /> : <button onClick={() => handleEditListing(listing._id)}>Edit Listing</button>}</p>

              {listing.hide ? (
                <button onClick={() => handleUnhideListing(listing._id)}>Unhide/Recover Listing</button>
              ) : (
                <button onClick={() => handleHideListing(listing._id)}>Hide/Delete Listing </button>
              )}
            <button onClick={() => handleDeleteListing(listing._id)}>Permanently Delete </button>
              <p>----</p>
            </div>
          ))}
      </div>) :null }
    </div>
  );
}

export default Dashboard;
