import React, { useState } from 'react';

function DeleteTheater({ theater, onUpdate }) {
  // Define state variables to store form field values
  const [message, setMessage] = useState('');


  const handleSubmit = async (e) => {
    const confirmation = window.confirm('Are you sure you want to DELETE this Theater?');

    if(confirmation) {
        const confirmation2 = window.confirm('Are you really sure you want to DELETE this Theater?');
if(confirmation2) {

    try {
      const response = await fetch(`/api/deleteTheater/${theater._id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include cookies
      });

      if (response.ok) {
        // Success: Theater edited successfully
        // You can display a success message here if needed
        console.log(response);
        // Notify the parent component of the update
        setMessage('Theater successfully deleted!')
        onUpdate();
      } else {
        // Error: An error occurred while editing the theater
        const errorData = await response.json();
        console.error(errorData.message); // Log the error message for debugging
        // Update the UI to display the error message to the user
        // For example, you can set it in a state variable to render in your component
        setMessage('Error deleting theater. Try again');
    } }catch (error) {
      // Handle fetch error
      console.error('An error occurred while deleting the theater.', error);
    }
}
    }
  };

  return (
    <>
    <button
    onClick={() => handleSubmit()}
    
  >
   Delete Theater
  </button>
<span>{message}</span>
    </>
  );
}

export default DeleteTheater;
