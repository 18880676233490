import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import RegisterPage from './components/RegisterPage';
import LoginPage from './components/LoginPage';
import Dashboard from './components/DashboardPage';
import Logout from './components/Logout';
import UserManagePage from './components/UserManagePage';
import HomePage from './components/HomePage';
import './App.css';
import Interface from './components/Interface';
import MoviePage from './components/MoviePage'
function App() {
  const [role, setRole] = useState(null); // Initialize with null
  const [isAuthorized, setIsAuthenticated] = useState(false); // Initialize with false
  useEffect(() => {
    // Check if the authentication cookie exists using js-cookie
    setIsAuthenticated(localStorage.getItem('isAuthenticated') === 'true');
    setRole(localStorage.getItem('role'));

  }, []);
  return (
    <Router>
      <div className="App">
        <header className="App-header">
        <nav>
  <span>
    <Link to="/">Home</Link> <span class="nav-separator"> - </span>
    <Link to="/interface">Interface</Link> <span class="nav-separator"> - </span>
    <Link to="/moviedata">Movie Data</Link>
  </span> <span class="nav-separator"> - </span>

  {isAuthorized ? (
    <>
      <span><Link to="/dashboard">Dashboard</Link></span> <span class="nav-separator"> - </span>
      {role === 'admin' && (
        <><span><Link to="/manageusers">User Manage</Link></span> <span class="nav-separator"> - </span></>
      )}
      <span><Link to="/logout">Logout</Link></span>
    </>
  ) : (
    <>
      <span><Link to="/login">Login</Link></span> 
    </>
  )}
</nav>


        </header>
        <div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/interface" element={<Interface />} />
          <Route path="/moviedata" element={<MoviePage />} />
          <Route path="/register" element={isAuthorized ? <Navigate to="/dashboard" /> : <RegisterPage />} />
          <Route path="/login" element={isAuthorized ? <Navigate to="/dashboard" /> : <LoginPage setRole={setRole} setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/dashboard" element={isAuthorized ? <Dashboard role={role} /> : <Navigate to="/login" />} />
          <Route path="/manageusers" element={isAuthorized && role === 'admin' ? <UserManagePage role={role} /> : <Navigate to="/login" />} />
          <Route path="/logout" element={isAuthorized ? <Logout setRole={setRole} setIsAuthorized={setIsAuthenticated} /> : <Navigate to="/login" />} />
          {/* Add other routes for different pages */}
        </Routes>
        </div>

      </div>
    </Router>
  );
}

export default App;
