
import { useNavigate } from 'react-router-dom';
import './main.css'


function Logout({setIsAuthorized, setRole}) {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear the authentication cookies

  // Set the authentication state to false and clear user role
  setIsAuthorized(false);
  setRole(null);
  localStorage.removeItem('isAuthenticated');
  localStorage.removeItem('role');

  // Redirect to the login page or any other appropriate action
    navigate('/login');
  };

  return (

    <div className="container">
<div className="sidebar"></div>
<div className="content">
    <div className="box">
        <div className="header">
            <h1>The Teleological Foundation of Los Angeles</h1>
            <hr/>
          </div>
          <div className="main">    
          <h2>Logout</h2>
      <p>Are you sure you want to log out?</p>
      <button onClick={handleLogout}>Logout</button>
                </div>

    </div>
</div>
<div className="sidebar"></div>
    </div>
  );
}

export default Logout;
